<template>
  <b-modal
    id="modal-edit-type"
    title="Edit Type"
    hide-footer
    centered
    size="md"
    @hidden="resetModal"
  >
    <validation-observer ref="form" slim>
      <validation-provider
        name="Type Name"
        rules="required|max:100"
        v-slot="{ errors }"
      >
        <b-form-group label="Type Name *" :invalid-feedback="errors[0]">
          <b-form-input
            placeholder="Enter type name"
            v-model="typeData.typeName"
            trim
            :class="{ 'is-invalid': errors.length }"
          />
        </b-form-group>
      </validation-provider>
      <b-row class="mt-4 mb-1">
        <b-col>
          <b-button
            class="reset"
            variant="outline-warning"
            block
            @click="cancel"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col>
          <b-button class="next" variant="warning" block @click="updateType">
            Update
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import api from '@/api'

export default {
  props: {
    typeData: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    form: {
      name: null
    },
    isBusy: false
  }),

  methods: {
    async updateType() {
      try {
        this.isBusy = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          this.isBusy = false
          return false
        }
        this.form.name = this.typeData.typeName
        await api.budget.updateType(this.typeData.id, this.form)
        this.$bvToast.toast('Success save edit type.', {
          headerClass: 'd-none',
          solid: true,
          variant: 'success'
        })
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(
            error.message ? error.message.messageEng : error.data.messageEng,
            {
              headerClass: 'd-none',
              solid: true,
              variant: 'danger'
            }
          )
        })
      }
      this.resetModal()
      this.cancel()
      this.$emit('refresh')
    },
    resetModal() {
      this.form = this.$options.data().form
    },
    cancel() {
      this.$bvModal.hide('modal-edit-type')
    }
  }
}
</script>

<style lang="scss" scoped>
.total {
  color: #2f80ed;
}

.remaining {
  color: #f7ac26;
}
</style>
