<template>
  <b-modal
    id="modal-delete-type"
    title="Delete Type"
    hide-header
    hide-footer
    centered
    size="md"
  >
    <div>
      <b-img
        :src="require('@/assets/logo-delete-type.svg')"
        fluid
        center
        alt="Responsive image"
      ></b-img>
    </div>
    <b-row class="mt-4 mb-1">
      <b-col>
        <b-button class="reset" variant="outline-warning" block @click="cancel">
          No, Cancel
        </b-button>
      </b-col>
      <b-col>
        <b-button class="next" variant="warning" block @click="deleteData">
          Delete
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  methods: {
    cancel() {
      this.$bvModal.hide('modal-delete-type')
    },
    deleteData() {
      this.$emit('delete')
      this.cancel()
    }
  }
}
</script>
