<template>
  <b-modal
    id="modal-new-type"
    title="New Type"
    hide-footer
    centered
    size="md"
    @hidden="resetModal"
  >
    <validation-observer ref="form" slim>
      <validation-provider
        name="Type Name"
        rules="required|min:3|max:100"
        v-slot="{ errors }"
      >
        <b-form-group label="Type Name *" :invalid-feedback="errors[0]">
          <b-form-input
            placeholder="Enter type name"
            v-model="form.name"
            trim
            :class="{ 'is-invalid': errors.length }"
          />
        </b-form-group>
      </validation-provider>
      <b-button class="next" variant="warning" block @click="submitType">
        Submit
      </b-button>
    </validation-observer>
  </b-modal>
</template>

<script>
import api from '@/api'

export default {
  data: () => ({
    form: {
      name: null
    },
    isBusy: false
  }),
  methods: {
    async submitType() {
      try {
        this.isBusy = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          this.isBusy = false
          return false
        }
        await api.budget.submitType(this.form)
        this.$bvToast.toast('Success save new type.', {
          headerClass: 'd-none',
          solid: true,
          variant: 'success'
        })
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(
            error.message ? error.message.messageEng : error.data.messageEng,
            {
              headerClass: 'd-none',
              solid: true,
              variant: 'danger'
            }
          )
        })
      }
      this.resetModal()
      this.cancel()
      this.$emit('refresh')
    },
    resetModal() {
      this.form = this.$options.data().form
    },
    cancel() {
      this.$bvModal.hide('modal-new-type')
    }
  }
}
</script>

<style lang="scss" scoped>
.total {
  color: #2f80ed;
}

.remaining {
  color: #f7ac26;
}
</style>
